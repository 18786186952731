import * as React from 'react';
import styled, { css } from 'styled-components';

import { BannerTitle } from '../../components/basic-components';
import { colors } from '../../style-constants';
import { below } from '../../media-query-utils';
import { addAngledEdge, CallToActionButton } from './utils';

import { phone1x, phone2x } from '../../images/home';

import appIcon from '../../images/icons/apps-s.svg';
import clockIcon from '../../images/icons/clocks-s.svg';
import { useI18n } from '../../hooks';

const Section = styled.section`
  position: relative;
  display: flex;
  height: 800px;

  align-items: center;
  justify-content: center;

  background-color: ${colors.ivory};

  ${addAngledEdge()}

  z-index: 1;

  ${below('smallDesktop')} {
    height: 761px;
  }

  ${below('mobile')} {
    height: 560px;
    padding: 34px;

    justify-content: initial;
  }
`;

/*
 * TODO: Use multiple images or video/gif from David
 */
const MobileFrame = styled.div`
  width: 326px;
  height: 636px;

  margin-top: 30px;

  background-image: url(${phone1x});
  background-image: image-set(url(${phone1x}) 1x, url(${phone2x}) 2x);
  background-size: cover;

  ${below('tablet')} {
    display: none;
  }
`;

const CallToActionSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 115px;

  align-items: center;

  ${below('smallDesktop')} {
    padding-left: 20px;
  }

  ${below('tablet')} {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  ${below('mobile')} {
    width: 100%;
    padding: 0;

    align-items: initial;
  }
`;

const Title = styled(BannerTitle)`
  position: relative;

  margin: 0;
  padding-left: 42px;

  ${({ icon }: { icon: string }) => css`
    &:before {
      content: '';
      position: absolute;
      display: block;

      top: 4px;
      left: 0;
      width: 26px;
      height: 39px;

      background: url(${icon});
      background-size: cover;

      ${below('mobile')} {
        display: none;
      }
    }
  `}

  ${below('smallDesktop')} {
    font-size: 30px;
    line-height: 1.8;
  }

  ${below('mobile')} {
    padding-left: 0;

    font-size: 24px;
    line-height: normal;

    & .extra-text {
      display: none;
    }
  }
`;

const Description = styled.p`
  width: 380px;
  margin-bottom: 30px;

  font-size: 18px;
  line-height: 1.67;

  ${below('mobile')} {
    width: 200px;
    font-size: 14px;
    line-height: 1.57;
  }
`;

const CTAWrapper = styled.div`
  position: relative;

  ${({ icon }: { icon: string }) => css`
    ${below('mobile')} {
      &:after {
        content: '';
        position: absolute;
        display: block;

        top: 0;
        right: -48px;
        width: 100px;
        height: 150px;

        opacity: 0.25;

        background: url(${icon});
        background-size: cover;
      }
    }
  `}
`;

const CallToAction = ({
  icon,
  title,
  description,
  link,
  id
}: {
  icon: string;
  title: string;
  description: string;
  link: string;
  id: string;
}) => {
  const { t } = useI18n();
  return (
    <CTAWrapper icon={icon}>
      <Title as="h2" icon={icon}>
        {title}
      </Title>
      <Description>{description}</Description>
      <CallToActionButton id={id} to={link}>{t`See All`}</CallToActionButton>
    </CTAWrapper>
  );
};

const Divider = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 0;
  margin-top: 72px;
  margin-bottom: 60px;
  border-bottom: 1px solid ${colors.whisper};

  ${below('mobile')} {
    margin-top: 62px;
    margin-bottom: 50px;
  }
`;

export default () => {
  const { t } = useI18n();

  return (
    <Section aria-label={t`See all Clock Faces and Apps`}>
      <MobileFrame />
      <CallToActionSection>
        <CallToAction
          icon={clockIcon}
          title={t`Clock Faces`}
          description={t`Personalize your Fitbit with a clock face that fits your style`}
          link="/clocks"
          id="seeAllClocksButton"
        />
        <Divider />
        <CallToAction
          icon={appIcon}
          title={t`Apps`}
          description={t`Get more out of your Fitbit with apps made by Fitbit and independent developers`}
          link="/apps"
          id="seeAllAppsButton"
        />
      </CallToActionSection>
    </Section>
  );
};
