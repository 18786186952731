import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { colors } from '../../style-constants';
import Explore from './Explore';
import Gallery from './Gallery';
import Devices from './Devices';
import Developer from './Developer';
import { ActiveNavItem } from '../../components/Header';
import { useI18n } from '../../hooks';

const HomeWrapper = styled.section`
  width: 100%;
  overflow: hidden;

  background-color: ${colors.white};
`;

const Home: React.FC = () => {
  const { t } = useI18n();

  const title = t`Discover apps and clock faces for your smartwatch`;

  return (
    <HomeWrapper aria-label={t`Fitbit App Gallery`}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta
          name="description"
          property="og:description"
          content={t`Discover apps and clock faces made for your Fitbit smartwatch`}
        />
      </Helmet>
      <ActiveNavItem item="home" />
      <Explore />
      <Gallery />
      <Devices />
      <Developer />
    </HomeWrapper>
  );
};

export default Home;
