import styled, { css } from 'styled-components';

import { ButtonLink } from '../../components/basic-components';

export const addAngledEdge = () => css`
  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: -7vw;
    height: 7vw;
    transform: skewY(-4deg);
    transform-origin: 100%;
    background: inherit;
    z-index: -1;
  }
`;

export const CallToActionButton = styled(ButtonLink)`
  font-size: 14px;
  z-index: 1;
`;
