import React from 'react';
import styled, { StyledComponent } from 'styled-components';

import { BannerTitle, FitLink } from '../../components/basic-components';
import { colors } from '../../style-constants';
import { below } from '../../media-query-utils';

import { versa4Hero, sense2Hero, senseHero } from '../../images/home';

import versa4 from '../../images/device_images/small-versa_4.png';
import sense from '../../images/device_images/small-sense.png';
import sense2 from '../../images/device_images/small-sense_2.png';

import { useI18n } from '../../hooks';

const Section = styled.section`
  position: relative;
  display: flex;
  height: auto;
  max-width: 890px;
  margin: auto;
  padding: 0 25px;

  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;

  background-color: ${colors.white};

  ${below('mobile')} {
    padding: 0;
    justify-content: start;
  }
`;

const createDeviceHeroImage = (
  hero1x: string,
  hero2x: string = hero1x
) => styled.div`
  width: 100%;
  padding-top: 66.66%;

  background-image: url(${hero1x});
  background-image: image-set(url(${hero1x}) 1x, url(${hero2x}) 2x);
  background-size: cover;
`;

const Versa4HeroImage = createDeviceHeroImage(versa4Hero);
const SenseHeroImage = createDeviceHeroImage(senseHero);
const Sense2HeroImage = createDeviceHeroImage(sense2Hero);

const createDeviceImage = (
  device1x: string,
  device2x: string = device1x
) => styled.div`
  display: inline-block;
  height: 60px;

  background-image: url(${device1x});
  background-image: image-set(url(${device1x}) 1x, url(${device2x}) 2x);
  background-size: cover;

  ${below('mobile')} {
    height: 50px;
  }
`;

const Versa4Image = styled(createDeviceImage(versa4))`
  width: 56px;
  margin-right: 19px;

  ${below('mobile')} {
    width: 47px;
    margin-right: 13px;
  }
`;

const SenseImage = styled(createDeviceImage(sense))`
  width: 56px;
  margin-right: 19px;

  ${below('mobile')} {
    width: 47px;
    margin-right: 13px;
  }
`;

const Sense2Image = styled(createDeviceImage(sense2))`
  width: 56px;
  margin-right: 19px;

  ${below('mobile')} {
    width: 47px;
    margin-right: 13px;
  }
`;

const DeviceSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;

  margin-top: 0;

  align-items: start;
  justify-content: space-between;

  ${below('largeTablet')} {
    width: 225px;
  }

  ${below('mobile')} {
    width: 100%;
    margin: 0;
  }
`;

const DeviceBodyWrapper = styled.div`
  width: 100%;
  padding: 25px 0;

  ${below('mobile')} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const Title = styled(BannerTitle)`
  position: relative;
  margin: 0;
  font-size: 30px;

  ${below('smallDesktop')} {
    line-height: 1.8;
  }

  ${below('mobile')} {
    font-size: 24px;
    line-height: normal;
  }
`;

const Description = styled.p`
  margin-bottom: 25px;

  font-size: 18px;
  line-height: 1.44;

  ${below('mobile')} {
    font-size: 14px;
    line-height: 1.57;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Device = ({
  title,
  description,
  link,
  deviceImage: DeviceImage,
  deviceHero: DeviceHero,
  id
}: {
  title: string;
  description: string;
  link: string;
  deviceImage: StyledComponent<any, any>;
  deviceHero: StyledComponent<any, any>;
  id: string;
}) => {
  const { t } = useI18n();

  return (
    <DeviceSection>
      <DeviceHero />
      <DeviceBodyWrapper>
        <TitleWrapper>
          <DeviceImage />
          <Title as="h2">{title}</Title>
        </TitleWrapper>
        <Description>{description}</Description>
        <FitLink id={id} external to={link} showArrow>
          {t`Learn More`}
        </FitLink>
      </DeviceBodyWrapper>
    </DeviceSection>
  );
};

const SectionTitle = styled(BannerTitle)`
  width: 100%;
  margin: 65px;
  text-align: center;
  font-size: 30px;

  ${below('mobile')} {
    margin: 24px;
    font-size: 24px;
    line-height: normal;
  }
`;

export default () => {
  const { t } = useI18n();

  return (
    <Section aria-label={t`Devices that support Fitbit Gallery`}>
      <SectionTitle as="h2">{t`Compatible smartwatches`}</SectionTitle>
      <Device
        title="Sense 2"
        description={t`Learn to manage stress, sleep better and live healthier with Sense 2 - our most advanced health and fitness smartwatch.`}
        link="https://www.fitbit.com/sense2"
        deviceImage={Sense2Image}
        deviceHero={Sense2HeroImage}
        id="sense2LearnMoreLink"
      />
      <Device
        title="Versa 4"
        description={t`Stay on top of your fitness and get better results from your routine with Versa 4 + Premium.`}
        link="https://www.fitbit.com/versa4"
        deviceImage={Versa4Image}
        deviceHero={Versa4HeroImage}
        id="versa4LearnMoreLink"
      />
      <Device
        title="Sense"
        description={t`The advanced health smartwatch with tools for stress management, heart health, SpO2, skin temperature & more.`}
        link="https://www.fitbit.com/sense"
        deviceImage={SenseImage}
        deviceHero={SenseHeroImage}
        id="senseLearnMoreLink"
      />
    </Section>
  );
};
