import * as React from 'react';
import styled from 'styled-components';

import { BannerTitle } from '../../components/basic-components';
import { colors } from '../../style-constants';
import { below } from '../../media-query-utils';
import { addAngledEdge, CallToActionButton } from './utils';

import { dev1x, dev2x } from '../../images/home';
import { useI18n } from '../../hooks';

const Section = styled.section`
  position: relative;
  display: flex;
  height: 400px;
  margin-top: 100px;

  align-items: center;
  justify-content: center;

  background-image: linear-gradient(
    to left,
    ${colors.blueLagoon},
    ${colors.sherpaBlue}
  );

  ${addAngledEdge()}

  z-index: 1;

  ${below('smallDesktop')} {
    height: 400px;
  }

  ${below('mobile')} {
    height: 340px;
    padding: 34px;
  }
`;

const StudioImage = styled.div`
  position: absolute;
  width: 585px;
  height: 387px;

  background-image: url(${dev1x});
  background-image: image-set(url(${dev1x}) 1x, url(${dev2x}) 2x);
  background-size: cover;

  left: 50%;
  bottom: -20px;

  ${below('smallDesktop')} {
    right: -10px;
  }

  ${below('mobile')} {
    top: -60px;
    left: 60%;
    width: 527px;
    height: 349px;
  }
`;

const CallToActionSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 890px;
  padding-left: 60px;

  align-items: start;

  ${below('mobile')} {
    padding: 0;
  }
`;

const Title = styled(BannerTitle)`
  position: relative;
  margin: 0;

  color: ${colors.white};

  font-size: 30px;

  ${below('mobile')} {
    width: 180px;
    font-size: 24px;
    line-height: normal;
  }
`;

const Description = styled.p`
  width: 285px;
  margin-bottom: 30px;

  color: ${colors.white};

  font-size: 16px;
  line-height: 1.5;

  ${below('mobile')} {
    width: 170px;
    font-size: 14px;
    line-height: 1.57;
  }
`;

export default () => {
  const { t } = useI18n();
  return (
    <Section>
      <CallToActionSection>
        <Title as="h2">{t`Become a Developer`}</Title>
        <Description>
          {t`Transform your passion into engaging apps and clocks for Fitbit users.`}
        </Description>
        <CallToActionButton
          title={t`Learn More about Becoming a Developer`}
          to="https://dev.fitbit.com"
          external
          id="learnMoreDeveloperButton"
        >
          {t`Learn More`}
        </CallToActionButton>
      </CallToActionSection>
      <StudioImage title={t`Fitbit Studio Screenshot`} />
    </Section>
  );
};
