import * as React from 'react';
import styled from 'styled-components';

import { BannerTitle } from '../../components/basic-components';
import { colors } from '../../style-constants';
import { below } from '../../media-query-utils';
import { CallToActionButton } from './utils';

import { hero1x, hero2x } from '../../images/home';
import { useI18n } from '../../hooks';

const Section = styled.section`
  display: flex;
  height: 550px;

  align-items: center;
  justify-content: center;

  background-image: linear-gradient(
    to left,
    ${colors.blueLagoon},
    ${colors.sherpaBlue}
  );

  ${below('smallDesktop')} {
    height: 460px;
  }

  ${below('mobile')} {
    height: 330px;

    padding: 40px 32px;

    align-items: initial;
  }
`;

const Text = styled(BannerTitle)`
  margin: 0;
  margin-bottom: 30px;
  color: ${colors.white};
  font-weight: normal;
  line-height: 1.35;

  ${below('smallDesktop')} {
    font-size: 30px;
    line-height: 1.4;
  }

  ${below('mobile')} {
    font-size: 24px;
    line-height: 1.42;
  }
`;

const CallToAction = styled.div`
  width: 560px;

  z-index: 1;

  ${below('smallDesktop')} {
    width: 416px;
  }
`;

const DevicesImage = styled.div`
  width: 428px;
  height: 519px;

  margin-top: 30px;

  background-image: url(${hero1x});
  background-image: image-set(url(${hero1x}) 1x, url(${hero2x}) 2x);
  background-size: cover;

  ${below('smallDesktop')} {
    width: 342px;
    height: 429px;
  }

  ${below('mobile')} {
    position: absolute;
    right: 30px;
    top: 175px;
    width: 171px;
    height: 216px;
    margin: 0;
  }
`;

const Button = styled(CallToActionButton)`
  ${below('mobile')} {
    display: none;
  }
`;

const MobileButton = styled(CallToActionButton)`
  display: none;

  ${below('mobile')} {
    display: inline-block;
  }
`;

export default () => {
  const { t } = useI18n();

  return (
    <Section>
      <CallToAction>
        <Text as="h1">
          {t`Discover apps and clock faces made for your Fitbit smartwatch`}
        </Text>
        <Button
          id="exploreGalleryApps"
          to="/apps"
        >{t`Explore the Gallery`}</Button>
        <MobileButton to="/apps">{t`Explore`}</MobileButton>
      </CallToAction>
      <DevicesImage />
    </Section>
  );
};
